import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Alert } from 'react-bootstrap';

import './operator-icon.scss';
import { CustomPopover } from './custom-popover';
import { IOperatorNode } from '../../../common/model/graphql-types';
import { RatingBox } from '../../../common/components/rating-box';

interface IPopoverProps {
  operator: IOperatorNode;
}

const OperatorPopover: React.FC<IPopoverProps> = ({ operator }) => {
  const image = operator.smallAvatar
    ? getImage(operator.smallAvatar.localFile.childImageSharp)
    : null;
  return (
    <div className="employee-popover">
      <div className="top">
        <div className={`avatar ${operator.rarity}`}>
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${operator.fullName}`}
          />
        </div>
        <p className="name">
          <span className="emp-name">{operator.shortName}</span>{' '}
          {operator.showNewTag && <span className="tag new">NEW!</span>}
        </p>
      </div>
      {!operator.areRatingsPending ? (
        <div className="bottom">
          <RatingBox
            game="cs-old"
            rating={operator.pveScore}
            ratingName="PVE"
            customClass="kr-character"
          />
          <RatingBox
            game="cs-old"
            rating={operator.pvpScore}
            ratingName="PVP"
            customClass="kr-character"
          />
        </div>
      ) : (
        <Alert variant="primary">
          <p>
            This operator was recently released in KR and we need time to
            properly test his/her abilities before reviewing and rating him/her.
          </p>
        </Alert>
      )}
    </div>
  );
};

interface IProps {
  operator: IOperatorNode;
  showLabel?: boolean;
  enablePopover?: boolean;
}

export const OperatorIcon: React.FC<IProps> = ({
  operator,
  showLabel,
  enablePopover
}) => {
  const image = operator.smallAvatar
    ? getImage(operator.smallAvatar.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box"
      popover={<OperatorPopover operator={operator} />}
    >
      <Link to={'/counter-side/operators/' + operator.slug}>
        <div className={`avatar ${operator.rarity} ${showLabel}`}>
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${operator.fullName}`}
          />
        </div>
        {showLabel && <span className="emp-name">{operator.shortName}</span>}
        {showLabel && operator.showNewTag && (
          <span className="tag new">NEW!</span>
        )}
      </Link>
    </CustomPopover>
  );
};
