import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Row, Col, Alert } from 'react-bootstrap';
import { MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../modules/common/components/section-header';
import { Seo } from '../modules/common/components/seo';
import { OperatorIcon } from '../modules/cs/common/components/operator-icon';
import { IOperatorNode } from '../modules/common/model/graphql-types';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { RatingBox } from '../modules/common/components/rating-box';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>
  },
  renderNode: {
    ['BLOCKS.PARAGRAPH']: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IOperatorNodes {
  nodes: IOperatorNode[];
}

interface IOperatorEntry {
  currentUnit: IOperatorNodes;
}

interface IProps {
  data: IOperatorEntry;
}

const OperatorDetails: React.FC<IProps> = ({ data }) => {
  const operator = data.currentUnit.nodes[0];

  return (
    <DashboardLayout
      className={'generic-page character-page operator '}
      game="cs"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/operators">Operators</Link>
        </li>
        <li className="divider">/</li>
        <li>{operator.fullName}</li>
      </ul>
      <div className="character-header">
        <OperatorIcon operator={operator} />
        <div className="character-details">
          <h1>
            {operator.shortName}{' '}
            {operator.showNewTag && <span className="tag new">NEW!</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <p className="description">{operator.title}</p>
          </div>
        </div>
        {!operator.areRatingsPending ? (
          <div className="character-ratings">
            <RatingBox
              game="cs-old"
              rating={operator.pveScore}
              ratingName="PVE"
              customClass="kr-character"
            />
            <RatingBox
              game="cs-old"
              rating={operator.pvpScore}
              ratingName="PVP"
              customClass="kr-character"
            />
          </div>
        ) : (
          <p className="no-ratings">Ratings are pending review.</p>
        )}
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      <SectionHeader title="Profile" />
      <Row xs={1} xl={2} xxl={3} className="info-list">
        <Col>
          <h5>Personal information</h5>
          <div className="info-list-row">
            <div className="category">Character ID</div>
            <div className="details">#{operator.unitId}</div>
          </div>
          <div className="info-list-row">
            <div className="category">Full Name</div>
            <div className="details">{operator.fullName}</div>
          </div>
          <div className="info-list-row">
            <div className="category">Rarity</div>
            <div className="details">
              <span className={`color-rarity ${operator.rarity}`}>
                {operator.rarity}
              </span>
            </div>
          </div>
        </Col>
        <Col>
          <h5>Stats</h5>
          <div className="info-list-row">
            <div className="category">Ship's HP</div>
            <div className="details">+{operator.operatorHp}%</div>
          </div>
          <div className="info-list-row">
            <div className="category">Ship's ATK</div>
            <div className="details">+{operator.operatorAtk}%</div>
          </div>
          <div className="info-list-row">
            <div className="category">Ship's DEF</div>
            <div className="details">+{operator.operatorDef}%</div>
          </div>
          <div className="info-list-row">
            <div className="category">Ship's CDR</div>
            <div className="details">+{operator.operatorHaste}%</div>
          </div>
        </Col>
        <Col>
          <h5>Availability</h5>
          <div className="info-list-row">
            <div className="category">Regions</div>
            <div className="details">
              {operator.regions.indexOf('KR') > -1 && <span>KR</span>}
              {operator.regions.indexOf('Global') > -1 && <span>, Global</span>}
            </div>
          </div>
        </Col>
      </Row>
      <SectionHeader title="Ability" />
      <div className="skills">
        <div className="skill-box">
          <div className="skill-header">
            <div className="skill-placeholder">
              <FontAwesomeIcon width="36" icon={faWaveSquare} />
            </div>
            <div>
              <h5 className="skill-name">{operator.operatorSkill.name}</h5>
              {operator.operatorSkill.cooldown && (
                <span className="pill cooldown">
                  <FontAwesomeIcon icon={faHistory} width="18" />{' '}
                  {operator.operatorSkill.cooldown} seconds
                </span>
              )}
            </div>
          </div>
          <div className="skill-content">
            <div className="skill-description">
              <h6>Level 1</h6>
              <p className="skill-description">
                {operator.operatorSkill.description}
              </p>
              <h6>Level 8</h6>
              {operator.operatorSkill.description_8 && (
                <p className="skill-description">
                  {operator.operatorSkill.description_8}
                </p>
              )}
              <hr />
              <h6>Skill trigger order</h6>
              {operator.triggerOrder && (
                <p className="release-order">{operator.triggerOrder}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297001"></div>
      <SectionHeader title="Analysis" />
      {operator.areRatingsPending ? (
        <Alert variant="primary">
          <Alert.Heading>
            This operator was recently released in KR server
          </Alert.Heading>
          <p>
            This operator was recently released in KR and we need time to
            properly test his abilities before reviewing and rating him.
          </p>
        </Alert>
      ) : (
        <div className="analysis">
          <h5 className="analysis-header">Review</h5>
          <div className="review raw">
            {operator.review ? (
              <div>{renderRichText(operator.review, options)}</div>
            ) : (
              <Alert variant="primary">
                <Alert.Heading>
                  No review is available for this operator yet.
                </Alert.Heading>
                <p>We will add it as soon as it's possible!</p>
              </Alert>
            )}
          </div>
          <SectionHeader title="Pros &amp; Cons" />
          {operator.pros && operator.cons ? (
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h5>Pros</h5>
                  <div className="raw list">
                    {renderRichText(operator.pros, options)}
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h5>Cons</h5>
                  <div className="raw list">
                    {renderRichText(operator.cons, options)}
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Alert variant="primary">
              <Alert.Heading>
                Pros &amp; Cons for this operator aren't available yet.
              </Alert.Heading>
              <p>We will add them as soon as it's possible!</p>
            </Alert>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};

export default OperatorDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const operator = data.currentUnit.nodes[0];

  return (
    <Seo
      title={operator.fullName + ' | Counter Side | Prydwen Institute'}
      description={operator.title}
      image={operator.smallAvatar}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulOperator(filter: { id: { eq: $contentfulId } }) {
      nodes {
        ...OperatorFieldsFragment
      }
    }
  }
`;
